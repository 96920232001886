import { LineString, Point } from 'gridtools/types/geojson';
import { ContainerType } from 'gridtools/types/go/telco/container';
import { GOChildren, GOParents } from 'types/go';
import { getColor } from 'app/utils/colors';
import { findParents } from 'app/utils/hierarchy';
import { AddressedObject, FiberCableLine, FiberCableObject } from './types';
import { DK_ACCESS_ADDRESS, TELCO } from './constants';

export const findInHierarchy = (obj: FiberCableObject, type: string,
                                checkDetails = true): FiberCableObject | undefined => {
  if (obj.type === type && (!checkDetails || hasDetails(obj))) {
    return obj;
  }

  for (const o of obj.located_in || []) {
    const result = findInHierarchy(o, type, checkDetails);
    if (result) {
      return result;
    }
  }
};

const hasDetails = (obj: FiberCableObject | GOChildren['telco_container']['root']) =>
  obj.details.type_source != null ||
  obj.details.specification != null ||
  obj.details.identification != null;

const getAddr = (obj: AddressedObject) => {
  const addr = obj.has && obj.has.find(h => h.type === DK_ACCESS_ADDRESS);
  return addr && addr.details && {
    address: addr.details.address_name,
    id: addr.id_source
  };
};

type RenderType = 'normal' | 'passthrough' | 'skip';
type NodeRenderResult = { type: RenderType, objects: FiberCableObject[] };
export const getNodeRender = ({ type, id_source }: FiberCableObject,
                              container: ObjInfo | null,
                              prev: _LineInfo,
                              next: _LineInfo,
                              findLine: (id: string) => boolean): NodeRenderResult => {
  const make = (type: RenderType): NodeRenderResult => ({ type, objects: [] });

  if (prev.line.type === TELCO.ISP_ROUTE || next.line.type === TELCO.ISP_ROUTE) {
    if (prev.line.type !== next.line.type) return make('normal');

    const p = getBuildingAndLocation(prev.line);
    const n = getBuildingAndLocation(next.line);
    if (
      p.building && n.building && p.building.id_source === n.building.id_source &&
      p.location && n.location && p.location.id_source === n.location.id_source
    ) {
      return { type: 'skip' as RenderType, objects: [n.building, n.location] };
    }

    return make('normal');
  }

  const id = id_source.replace(/_(start|end)$/, '');

  if (type === TELCO.ROUTE_NODE) {
    return make(container && findLine(id) ? 'passthrough' : 'skip');
  }

  if (type === TELCO.CONDUIT_NODE && !findLine(id)) {
    return make(container ? 'passthrough' : 'skip');
  }

  return make('normal');
};

type ObjInfo = {
  label?: string, spec?: string, location?: Point,
  objects: FiberCableObject[];
  mainObjectType: string;

  address?: {
    address: string | undefined;
    id: string;
  };
};

const getObjectInfo = (obj: FiberCableObject, o?: FiberCableObject): ObjInfo | null => {
  if (!o || !o.details) return null;

  const details = o && o.details;

  const addrObj = o.type === TELCO.CONDUIT_ADAPTER
    ? findInHierarchy(o, TELCO.ROUTE_NODE, false)
    : o;

  const objects = [obj];
  if (o !== obj) {
    objects.push(o);
  }
  if (addrObj && addrObj !== o && addrObj !== obj) {
    objects.push(addrObj);
  }

  const address = addrObj && getAddr(addrObj);

  let type = (o || obj).type;
  if (type === TELCO.CONTAINER) {
    type = (o || obj).details.type || type;
  }

  return {
    label: `${details.type_source}: ${details.identification || ''}`,
    spec: details.specification || '',
    address,
    location: details.location,
    objects,
    mainObjectType: type,
  };
};

const getIspObjectInfo = (node: FiberCableObject, line: FiberCableLine, createText: boolean): ObjInfo | null => {
  const { location, building, text } = getBuildingAndLocation(line);
  return {
    label: createText ? text : '',
    mainObjectType: node.type,
    address: (location && getAddr(location))
      || (building && getAddr(building)),
    location: (location && location.details.location)
      || (building && (building.details as any).area)
      || undefined,
    objects: [
      node,
      location!,
      building!
    ].filter(Boolean),
  };
};

const getBuildingAndLocation = (line: FiberCableLine) => {
  const location =
    findInHierarchy(line as any, 'telco_building_floor', false) ||
    findInHierarchy(line as any, 'telco_logical_location', false);
  const building = findInHierarchy(line as any, 'telco_building', false);

  const locationText = location && location.type === 'telco_logical_location' && location.details.identification;
  const text = locationText || [
    building && building.details.identification,
    location && location.details.identification
  ].filter(Boolean).join(', ');
  return { location, building, text };
};

export const getNodeContainer = (node: FiberCableObject): ObjInfo | null => {
  return node.containers ? getObjectInfo(node, node.containers[0]) : null;
};

export const getInfo = (obj: FiberCableObject, lines: FiberCableLine[]): ObjInfo | null => {
  const toInfo = (o?: FiberCableObject) => getObjectInfo(obj, o);

  const getIspInfo = (nodeId: string, createText: boolean) => {
    const id = nodeId.replace(/_(start|end)$/, '');
    const line = lines.find(l => l.id_source === id);
    return line ? getIspObjectInfo(obj, line as any, createText) : null;
  };

  if (obj.type === TELCO.FIBER_CABLE_NODE) {
    const isp = findInHierarchy(obj, TELCO.ISP_ROUTE_NODE, false);
    if (isp) {
      return getIspInfo(isp.id_source, true);
    }

    // find container
    const info = toInfo(findInHierarchy(obj, TELCO.CONTAINER));
    if (!info) {
      return null;
    }

    const { spec, ...rest } = info;
    return rest;
  }

  if (obj.type === TELCO.ISP_ROUTE_NODE) {
    return getIspInfo(obj.id_source, false);
  }

  if (obj.type === TELCO.CONDUIT_NODE || obj.type === TELCO.ROUTE_NODE) {
    const info = toInfo((hasDetails(obj) && obj)
      || findInHierarchy(obj, TELCO.CONTAINER)
      || findInHierarchy(obj, TELCO.CONDUIT_ADAPTER)
      || findInHierarchy(obj, TELCO.ROUTE_NODE));
    return info && info.address
      ? info
      : findRouteNodeAddress(info, obj);
  }

  if (obj.type === TELCO.CONTAINER) {
    return toInfo(obj);
  }

  return null;
};

const findRouteNodeAddress = (info: ObjInfo | null, obj: FiberCableObject) => {
  for (const node of findParents('telco_route_node_vertex', obj as any)) {
    const n = node as any as FiberCableObject;
    const addr = getAddr(n);
    if (!addr) continue;

    if (!info) {
      const { location } = n.details;
      info = { objects: [ n ], location, label: 'Tracéknude', mainObjectType: 'telco_route_node' };
    }
    info.address = addr;
    break;
  }
  return info;
};

export type _LineInfo = {
  length: number;
  above?: string;
  below: string;
  isRoute: boolean;
  centerline: (LineString | undefined)[];
  op_status: string | null;
  objects: FiberCableLine[];
  line: FiberCableLine;
};

type _Child<T extends keyof GOChildren = keyof GOChildren> = GOChildren[T]['root'];
type _Parent<T extends keyof GOParents = keyof GOParents> = GOParents[T]['root'];
const findInChildren =
  <Type extends keyof GOChildren>(type: Type, contains?: _Child[]) =>
    (contains || []).find(c => c.type === type) as _Child<Type> | undefined;
const findInParents =
  <Type extends keyof GOParents>(type: Type, located_in?: _Parent[]) =>
    (located_in || []).find(l => l.type === type) as _Parent<Type> | undefined;

export const getLineInfo = (line: FiberCableLine): _LineInfo => {
  const objLength = line.type === TELCO.ISP_ROUTE
    ? line.details.calculated_length_m
    : line.details.geographical_length_m;
  const length = objLength || 0;
  const childConduit = findInChildren('telco_conduit', line.contains);

  const getParts = (cnd?: _Child<'telco_conduit'>) => {
    if (!cnd) {
      return [];
    }
    return [
      cnd.details.identification,
      cnd.details.specification
    ].filter(s => s);
  };

  const objects = [line];
  if (childConduit) objects.unshift(childConduit);

  const isp = line.type === TELCO.ISP_ROUTE;
  const isRoute = line.type === TELCO.ROUTE || isp;

  let below: string;
  if (isp) {
    const { building, location, text } = getBuildingAndLocation(line);
    below = text;
    if (location) objects.unshift(location as any);
    if (building) objects.unshift(building as any);
  } else {
    below = getParts(line.type === 'telco_conduit' ? line : childConduit).join(', ');
  }

  return {
    length,
    isRoute,
    above: (!isRoute && getParts(childConduit)[0]) || '',
    below,
    op_status: isRoute ? line.details.op_status : null,

    centerline: [(!isp && line.details.centerline) || undefined],

    objects,
    line
  };
};

export const findConduitContainer = (cnd: FiberCableLine) => {
  return cnd.type === TELCO.CONDUIT
    && findInParents('telco_container', cnd.located_in);
};

type Obj = { id_source: string, contains?: Obj[] };
export const findLine = (lines: Obj[], lineId: string, cableId: string) => {
  for (const l of lines) {
    if (!l.contains || l.contains.length === 0) continue;

    if (l.contains.some(c => c.id_source === cableId)) {
      // eslint-disable-next-line no-console
      console.log(l.id_source);
    }

    const found = l.contains.some(c => c.id_source === cableId)
      ? l.id_source === lineId
      : findLine(l.contains, lineId, cableId);
    if (found) return true;
  }

  return false;
};

export const collectLineIds = (lines: Obj[], cableId: string): Map<string, any> => {
  const find = (parents: string[], children?: Obj[]): string[] => {
    for (const ch of children || []) {
      if (ch.id_source === cableId) return parents;

      const data = find([...parents, ch.id_source], ch.contains);
      if (data.length) {
        return data;
      }
    }
    return [];
  };

  const map = new Map<string, any>();
  lines.forEach(l => {
    const path = find([l.id_source], l.contains);
    path.forEach(p => map.set(p, null));
  });

  return map;
};

type ShapeInfo = {
  color?: string;
  stroke?: string;
  shape?: 'circle' | 'square',
  small?: boolean;
};

export const getShapeInfo = (type: string): undefined | ShapeInfo => {
  if (type === TELCO.CONDUIT_ADAPTER || type === 'telco_optical_splice') {
    return { color: getColor(type) };
  }

  if (type === 'telco_fiber_port') {
    return { small: true, color: '#fff' };
  }

  if (type === 'telco_customer') {
    return {
      color: '#4472c4',
      stroke: '#4472c4',
      small: true,
    };
  }

  switch (type as ContainerType) {
    case 'HUB': return { color: '#ec0d0d' };
    case 'Street Cabinet': return { color: '#c20dbf', shape: 'square' };
    case 'Underground Utility Box': return { color: '#1652ec' };
  }
};
