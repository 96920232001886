import axios, { AxiosError, AxiosResponse } from 'axios';
import { api, error as _error, request as _request } from 'lib/types/go';
import { Details } from 'lib/types/go/options';
import { Params } from 'lib/utils/http';

import { catchError, setSuccess } from './response';

function getParams(accessToken: string, opts: api.TraceOutOptions<Details>, resource: 'conduit_nodes' | 'fiber_nodes' | 'fiber_ports'): string {
  const params = new Params();
  const url = window.location.href;
  params.set('access_token', accessToken);
  params.set('details', opts.details === undefined ? 'full' : opts.details);
  /* if user presses the visualisation-tab on a customer, 
  ** or 
  ** if checkbox in the tracing dialog for fiber cable is checked
  ** the trace-out request will include pon_upstream_tracing parameter
  */
  if (url.includes('customer') || url.includes('pon_upstream_tracing')) {
    params.set('pon_upstream_tracing', 'true');
  }
  if (opts.date !== undefined) {
    params.set('date', opts.date); 
  }
  if (opts.depth !== undefined) {
    params.set('depth', opts.depth.toString()); 
  }
  if (opts.direction !== undefined) {
    params.set('direction', opts.direction); 
  }
  if (opts.include_parent_structure !== undefined) {
    params.set('include_parent_structure', opts.include_parent_structure.toString()); 
  }
  if (opts.output_geoms !== undefined) {
    params.set('output_geoms', opts.output_geoms.toString()); 
  }
  if (opts.stop_distance_m !== undefined) {
    params.set('stop_distance_m', opts.stop_distance_m.toString()); 
  }
  if (opts.version !== undefined) {
    params.set('version', opts.version); 
  }
  return params.toString();
}

export function traceOut<IDOnly, Full, Debug>(namespace: 'dk' | 'telco', resource: 'conduit_nodes' | 'fiber_nodes' | 'fiber_ports') {
  const error = (err: AxiosError) => catchError<_error.ObjectError>(err);
  const success = (response: AxiosResponse) => setSuccess<any>(response);
  return (baseURL: string, accessToken: string) => {
    function makeRequest(opts: api.TraceOutOptions<'id_only'>): _request.Request<IDOnly, _error.ObjectError>;
    function makeRequest(opts: api.TraceOutOptions<'full'>): _request.Request<Full, _error.ObjectError>;
    function makeRequest(opts: api.TraceOutOptions<'debug'>): _request.Request<Debug, _error.ObjectError>;
    function makeRequest(opts: api.TraceOutOptions<Details>) {
      const source = axios.CancelToken.source();
      const cancelToken = source.token;
      const cancel = source.cancel;
      const params = getParams(accessToken, opts, resource);
      const url = `${baseURL}/${namespace}/${resource}/${opts.id}/trace_out?${params}`;
      const request = axios.get(url, { cancelToken }).then(success).catch(error);
      return { cancel, request };
    }
    return makeRequest;
  };
}
